import React from 'react';
import { Link } from 'gatsby';

import { BaseTemplate } from '@templates';
import { SEO, PageSection } from '@components';

import './index.scss';

const sections = [
  {
    id: 'applicability',
    label: 'Applicability'
  },
  {
    id: 'personal-info',
    label: 'Personal Information We Collect'
  },
  {
    id: 'data-security',
    label: 'Data Security'
  },
  {
    id: 'data-retention',
    label: 'Data Retention'
  },
  {
    id: 'data-collection',
    label: 'Automated Data Collection Technologies'
  },
  {
    id: 'third-party',
    label: 'Third-Party Services'
  },
  {
    id: 'contact-us',
    label: 'Contact Us'
  }
];

const sidebar = sections.map((section, index) => (
  <li key={`privacy-sidebar-item-${index + 1}`} className="sidebar-item">
    <Link className="sidebar-link" to={`/privacy#${section.id}`}>
      {`${index + 1}. ${section.label}`}
    </Link>
  </li>
));

const IndexPage = () => (
  <BaseTemplate>
    <SEO title="Privacy Policy" />
    <PageSection className="subpage-privacy-policy">
      <div className="privacy-policy-container">
        <div className="sidebar display-none-is-mobile">
          <h4 className="sidebar-header text-sh700">On this page</h4>
          <ul className="section-title-list">{sidebar}</ul>
          <h4 className="text-sh700 sidebar-header">Other documents</h4>
          <ul className="section-title-list">
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/terms">Terms of Use</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/restricted-businesses">Restricted Businesses</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/third-parties">List of Third Parties</Link>
            </li>
          </ul>
        </div>
        {/* TODO:  could be separated per section */}
        <div className="content">
          <div className="title">
            <h2>Privacy Policy</h2>
            <br />
            <h4>Last updated: February 12, 2020</h4>
          </div>
          <div id="introduction" className="section">
            <p>Thank you for using PayMongo!</p>
            <p>
              Capitalized terms used but not defined shall have the
              respective meanings given to them in the <Link to="/terms">Terms of Use</Link>. We
              created this Privacy Policy (“Policy”) to give you confidence as you visit and use
              our Service. This Policy covers how we collect, use, store, and share your information
              when you use our Service. All references to “we”, “us”, “our”, or “PayMongo” refer to
              PayMongo Philippines, Inc., a corporation duly organized and existing under Philippine
              law. All references to “you”, “your”, or “user” relate to the person or entity who
              registered an Account on PayMongo to use or access the Services.
            </p>
            <p class="content-highlight">
              Please read this Policy carefully. By clicking the applicable button or checkbox to
              indicate your acceptance to this Policy and our Terms, or otherwise by creating an
              Account and using the Service, you represent and agree that you have read, understand,
              and agree to be bound by both this Policy and our Terms as binding agreements.
            </p>
            <p>
              We may update this Policy from time to time. If you continue using the Service after
              we make any changes to this Policy, you accept and agree to be bound by those changes.
              The most updated version of this Policy will always be posted on the Service, so we
              encourage you to check the Policy periodically. In certain situations, we may notify
              you about material changes to the Policy. You are responsible for ensuring we have an
              up-to-date, active, and deliverable email address on file for you, and you agree that
              your use of the Service will be subject to any updated Policy posted on the Service,
              whether or not you receive notice of such changes.
            </p>
          </div>

          <div id="applicability" className="section">
            <p className="section-title">1. Applicability</p>
            <p>
              This Policy applies to personal information we collect on the Service. It does not
              apply to any non-personal information or any information collected by any third party.
              As used in this Policy, “personal information” means information that can be used to
              identify any natural person either directly or indirectly.
            </p>
            <p>
              Moreover, if you are a Customer, we may collect Customer Data either directly from you
              or from the user using our Payment Processing Services.
            </p>
          </div>

          <div id="personal-info" className="section">
            <p className="section-title">2. Personal Information We Collect</p>
            <p className="subsection-title">
              2.1 Personal Information You Voluntarily Provide to PayMongo
            </p>
            <p>
              When you register for an Account, we require that you provide us with certain personal
              information about you. This includes, but is not limited to, your first and last name,
              e-mail address, and the name of your business. As part of the account registration and
              verification process, we may also ask you for certain other information, such as your
              phone number and your business’s tax identification number, as well as documents that
              include personal information about you. These documents include, but are not limited
              to, government-issued identification including identification containing photo and
              signature, bank statements, proof of income, documentation verifying the legal
              existence of your business and its beneficial owners and principals, bank statements
              and related banking information, and other financial documents to verify your identity
              and assess the risk associated with your business. Even if you access and use the
              Service without registering for an Account, you may choose to provide us with certain
              personal information about you (for example, you may want to sign up for our
              newsletters or RSVP for events). The collection of this information is necessary to
              provide the functionality of the Service and/or to comply with applicable laws and
              regulations related to the Service’s product offerings.
            </p>
            <p>
              If you are a Customer, when you make a payment through a user’s website or application
              in relation to a Transaction, we will receive certain Customer Data. Customer Data may
              include:
            </p>
            <ul>
              <li key="payment-cust-data-1">First and last name;</li>
              <li key="payment-cust-data-2">Physical and/or mailing address;</li>
              <li key="payment-cust-data-3">
                Financial information, including but not limited to credit or debit card numbers or
                bank account information;
              </li>
              <li key="payment-cust-data-4">Email address; and</li>
              <li key="payment-cust-data-5">Phone number.</li>
            </ul>
            <p>
              The collection of this Customer Data is necessary to provide the functionality of the
              Service (mainly, to process the Transaction) and/or to comply with applicable laws and
              regulations related to the Service’s product offerings. We may use Customer Data for
              the following purposes: (i) providing and improving the Services; (ii) internal usage,
              including but not limited to, data analytics and metrics so long as such Customer Data
              has been anonymized and aggregated with other customer data; (iii) complying with
              applicable legal requirements and assisting law enforcement agencies by responding to
              requests for the disclosure of information in accordance with applicable law; and (iv)
              any other purpose for which consent has been provided by the Customer.
            </p>

            <p className="subsection-title">2.2 Personal Information We Collect Automatically</p>
            <p>
              We collect certain information about you automatically as you access and use the
              Service and may similarly collect information from Customers. This includes:
            </p>
            <ul>
              <li key="info-auto-collect-1">
                Device information, such as a unique device identifier; and
              </li>
              <li key="info-auto-collect-2">
                Location information, such as your IP address or geo-location.
              </li>
            </ul>
            <p>
              This information does not identify you directly but may nevertheless be considered
              personal information.
            </p>

            <p className="subsection-title">2.3 How We Use Personal Information</p>
            <p>
              We use the personal information that we collect to make the Service available to our
              users, to comply with our legal obligations under anti-money laundering and related
              laws, to market and promote the Service, to improve the Service, and to protect our
              legal rights.
            </p>
            <p>Examples of the ways in which we may use your personal information include:</p>
            <ul>
              <li key="personal-info-use-1">
                Allowing you to register for an Account and use the Payment Processing Services;
              </li>
              <li key="personal-info-use-2">
                Verifying your identity in compliance with our obligations under applicable law;
              </li>
              <li key="personal-info-use-3">Identifying fraudulent or illegal activity;</li>
              <li key="personal-info-use-4">
                Providing you with information, products or services that you request from us;
              </li>
              <li key="personal-info-use-5">
                Carrying out our obligations and enforcing our rights arising from any contracts
                entered into between you and us, including for billing and collection;
              </li>
              <li key="personal-info-use-6">
                Notifying you about changes to the Service, such as updates to this Policy; and,
              </li>
              <li key="personal-info-use-7">
                Understanding how our users interact with the Service to improve the Service and our
                product offerings.
              </li>
            </ul>
            <p>
              In addition to the above, if you choose to opt in to receive marketing and promotional
              communications from us, we may use your personal information to contact you about our
              products. If you no longer want to receive marketing-related emails from us, you may
              opt-out via the unsubscribe link included in such emails, or by whatever other means
              we allow you to unsubscribe. We will try to comply with your request(s) as soon as
              reasonably practicable. Please note that if you opt-out of receiving marketing-related
              emails from us, we may still send you important administrative messages that are
              required to provide you with our Services.
            </p>
            <p>
              We may also use your personal information for any other purposes with your consent.
            </p>

            <p className="subsection-title">2.4 How We Share Personal Information</p>
            <p>
              We may disclose or share personal information that we collect in the following
              circumstances:
            </p>
            <ul>
              <li key="share-personal-info-1">
                With our subsidiaries and affiliates (please refer to
                the <Link to="/third-parties">List of Third Parties</Link> for the list thereof);
              </li>
              <li key="share-personal-info-2">
                With our contractors and vendors who support aspects of the Service and our
                business, including: services related to website hosting, data analysis, information
                technology and related infrastructure, bank account identity and authentication,
                helpdesk and support, user identity verification, and fraud prevention (please refer
                to <Link to="/third-parties">List of Third Parties</Link> for the list of said
                contractors and vendors);
              </li>
              <li key="share-personal-info-3">
                With our banking partners, including Payment Method Providers and Acquirers, who
                enable the processing of Transactions via the Payment Processing Services (please
                refer to the <Link to="/third-parties">List of Third Parties</Link> for the list thereof);
              </li>
              <li key="share-personal-info-4">
                With a buyer or other successor in the event of a merger, reorganization,
                dissolution or other sale or transfer of some or all of our assets, whether as a
                going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
                personal information held by us about our users is among the assets transferred (in
                such event, we will provide notice to our users of such transfer);
              </li>
              <li key="share-personal-info-5">
                When we believe, in good faith, that disclosure is necessary to prevent physical
                harm or financial loss, to report suspected illegal activity, or to investigate
                violations of our Terms of Service or any other applicable policies; and
              </li>
              <li key="share-personal-info-6">With your express consent.</li>
            </ul>
            <p>
              Any third-party entity to whom we disclose your personal information is contractually
              required to comply with confidentiality standards and establish adequate safeguards
              for data privacy, undertake to respect your right to privacy and comply with
              applicable laws. We also require that these third-party entities use information
              shared with them only for authorized purposes and follow our reasonable directions
              with respect to this information.
            </p>
            <p>
              Additionally, we may share your personal information as required to comply with any
              subpoena, court order, similar legal process, including to respond to any government
              or regulatory request. To the extent permitted by law, we will notify you if we
              receive government requests about your data.
            </p>
            <p>
              Finally, we may disclose anonymized, non-personal information about the Service and
              our users without restriction.
            </p>
            <p>
              If you do not wish any of your personal information to be shared to any third party or
              for any of the indicated purposes, please <a href="mailto:dpo@paymongo.com">contact
              us</a> immediately.
            </p>

            <p className="subsection-title">2.5 Accessing and Correcting Your Information</p>
            <p>
              You are solely responsible for ensuring that any personal information that you provide
              to us is accurate. You may be able to view and update certain personal information
              that we have about you by logging into your Account or by emailing address provided at
              the end of this Policy. Please note that we reserve the right to reject any changes
              you make to your personal information and to reject any requests to change your
              personal information submitted through inappropriate channels.
            </p>
          </div>

          <div id="data-security" className="section">
            <p className="section-title">3. Data Security</p>
            <p>
              We use commercially reasonable physical, technical, and administrative measures to
              secure your personal information from accidental loss and from unauthorized access,
              use, and disclosure. For example, we (i) have implemented a strict data security
              policy, (ii) restrict access to personal information to employees, contractors, and
              other service providers on a need-to-know basis, (iii) use industry-standard
              encryption technology to secure data, (iv) train our personnel on privacy issues and
              have appointed a data privacy officer, (v) review the privacy practices of new
              products and services that we integrate into our Service, and (vi) require our
              personnel to sign confidentiality agreements that extend to your personal information.
              However, the transmission of information via the Internet is not completely secure. As
              we cannot guarantee the security of information transmitted to or from us, we are not
              responsible for any unauthorized access to and disclosure of any information you send
              to or receive from us. Any transmission of personal information is at your own risk.
            </p>
            <p>
              Please also keep in mind that the safety and security of your information also depends
              on you. You are responsible for keeping your account information, including your
              password, confidential. We ask you not to share your password with anyone. If you have
              reason to believe that your data is no longer secure, please contact us immediately at
              the email address, mailing address or telephone number listed at the end of this
              Policy.
            </p>
          </div>

          <div id="data-retention" className="section">
            <p className="section-title">4. Data Retention</p>
            <p>
              We may retain your personal information for the longer of: (i) six (6) months after
              receipt of your request to delete your Account; (ii) the length of time required by
              law; (iii) the length of time required by our compliance program; or (iv) the length
              of time required by our banking partners, including Payment Method Providers and
              Acquirers. Please note that if you delete your Account, we may still retain your
              personal information as explained above. You may delete your Account through the
              Dashboard, or you may email the address provided at the end of this Policy.
            </p>
          </div>

          <div id="data-collection" className="section">
            <p className="section-title">5. Automated Data Collection Technologies</p>
            <p>
              As you navigate through and interact with the Service, we may use automatic data
              collection technologies to collect certain information about your equipment, browsing
              actions and patterns, as further described in this Policy.
            </p>

            <p className="subsection-title">5.1 Cookies and Analytics</p>
            <p>
              We may use cookies and other technologies to automatically collect information about
              your use of the Service. You can learn more about these technologies below. We may use
              this information to provide you with a better user experience, to comply with our
              legal obligations under anti-money laundering and related laws, to protect you and
              detect irregular or suspicious account activities, to customize our services and
              content for you, and to better understand how our users interact with the Service.
            </p>
            <p>
              <u>Cookies</u>. A cookie is a small file placed on your computer when you visit
              certain websites. Cookies may be either first-party or third-party cookies, and they
              may be either permanent or temporary (i.e. session) cookies. It may be possible to
              refuse to accept cookies by activating the appropriate setting within your browser.
              However, if you disable or refuse cookies, please note that some parts of the Service
              may be inaccessible or may not function properly.
            </p>
            <p>
              <u>Other Technologies</u>. We may use other third-party services that automatically
              collect information about you to better understand how you use and interact with the
              Service. For example, we may use third-party vendors to provide us with services
              surrounding analytics, advertising, and cybersecurity. The information collected
              through this process by the third-party service providers does not enable us or them
              to identify your name, contact details or other personal information that directly
              identifies you unless you choose to provide these.
            </p>

            <p className="subsection-title">5.2 “Do Not Track” Signals</p>
            <p>
              To the extent that we receive any Do-Not-Track signals, we will not comply with them.
            </p>
          </div>

          <div id="third-party" className="section">
            <p className="section-title">6. Third-Party Services</p>
            <p>
              Any third-party services integrated with the Service shall be subject to the policies
              and practices of such third parties, and we are not responsible for how they collect,
              use, and share your personal information. We encourage you to review the privacy
              practices and policies of such third parties. We make no guarantees about, and assume
              no responsibility for the information, services, or data privacy practices of third
              parties.
            </p>
          </div>

          <div id="contact-us" className="section">
            <p className="section-title">7. Contact Us</p>
            <p>
              To ask questions or comment about this Policy and our privacy practices, <a href="mailto:dpo@paymongo.com">
              contact us</a>.
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  </BaseTemplate>
);

export default IndexPage;
